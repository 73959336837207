const ACTIONS = {
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_FAIL: "FETCH_USER_FAIL",
  FETCH_EVENTS: "FETCH_EVENTS",
  FETCH_EVENTS_SUCCESS: "FETCH_EVENTS_SUCCESS",
  FETCH_EVENTS_FAIL: "FETCH_EVENTS_FAIL",

  SIGN_UP: "SIGN_UP",
  LOGOUT: "LOGOUT",

  FETCH_REFERRALS: "FETCH_REFERRALS",
  FETCH_REFERRALS_SUCCESS: "FETCH_REFERRALS_SUCCESS",
  FETCH_REFERRALS_FAIL: "FETCH_REFERRALS_FAIL",

  FETCH_FEEDS: "FETCH_FEEDS",
  FETCH_FEEDS_SUCCESS: "FETCH_FEEDS_SUCCESS",
  FETCH_FEEDS_FAIL: "FETCH_FEEDS_FAIL",
};

export default ACTIONS;
