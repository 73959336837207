import React from "react"
import "./Landing2.css"
import back2 from "../../Assets/land2.webp"
import back2mob from "../../Assets/land2textmob.webp"
import land2text from "../../Assets/What is Campus Ambassador Program_.svg"
import land2textpp from "../../Assets/land2-textppppp.svg"
import scooter from "../../Assets/scooter.svg"
import Navbar from "../Hero Section/Navbar"

const Landing2 = ({ isActive }) => {
	return (
		<>
        <Navbar/>
		<div className={`relative Landing2wrapper ${isActive ? "active" : ""}`}>
			<img src={back2} className='bg_img_2' alt='bg-2-img' />
			<img src={back2mob} className='bg_img_2_mob' alt='bg' />
			<div className='landing2'>
				<div className='landing2-text-box'>
					<div className='landing2-text sansita-bold-italic'>
						<h1>What is Campus Ambassador Program?</h1>
					</div>
					<div className='landing2-details'>
						<h1>
							As part of the student campus ambassador program, THOMSO offers
							students the chance to represent and promote the organization at
							their colleges and universities. Their responsibilities comprise
							developing our presence and promoting THOMSO events among students
							and educators by serving as a link between their colleges and us.
						</h1>
					</div>
				</div>
				<div className='scooter-div'>
					<div className='scooter-img-div'>
						<img src={scooter} className='scooter-img' alt='scooter-img' />
					</div>
				</div>
			</div>
			<div className='landing2-mobile'>
        <Navbar/>
        <div className='landing2-text-box'>
			<div className="landing2-text">
          		<img src={land2text} className='land2text-img' alt='land2-text' />
			</div>
				  <div className='landing2-details'>
				  	<h1>
            As part of the student campus ambassador program, THOMSO offers students the chance to represent and promote the organisation at their colleges and universities. <br/><br/> 
            Their responsibilities comprise developing our presence and promoting THOMSO events among students and educators by serving as a link between their colleges and us.
				  	</h1>
				  </div>
				</div>
				<div className='scooter-div'>
					<div className='scooter-img-div'>
						<img src={scooter} className='scooter-img' alt='scooter-img' />
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default Landing2
